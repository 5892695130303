.fuzzy-content{
  display:grid;
  /* grid-template-columns: 1fr max-content; */
  grid-auto-flow: row;
  align-items:center;

  padding:10px;

  grid-gap:5px;

  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.fuzzy-content>div:nth-child(1){
  display:grid;
  grid-template-columns: 1fr max-content;
  align-items:center;
}
.fuzzy-wrap{


  padding:5px;
  margin-bottom:1rem;
}

.fuzzy-tags{
  float: left;
  margin:0px 10px 10px 0px;
  position:relative;
}



.fuzzy-tags span:nth-child(1){
  position:absolute;
  margin-top:-20px;
  margin-left:4px;
}